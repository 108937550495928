@media screen and (max-width: 1024px) {
  .background-image {
    display: none;
    opacity: 0.6;
  }

  .login-form {
    align-items: flex-start;
    position: relative;
  }

  /*   body {
    background-image: url('../../images/desktop.jpg');
  } */

  h1,
  h2 {
    color: white;
  }
}

.panel-header {
  border: none !important;
  color: #005a76;
  margin-bottom: 10px !important;
  background-color: #d9e7eb;
  border-radius: 55px !important;
  text-align: center;
  justify-content: center;
  font-size: 18px!important;
}

.panel-button {
  margin-bottom: 10px;
  border: 1px solid #8e9699;
  border-radius: 55px;
  text-align: center !important;
  background-color: #8e9699 !important;
  font-weight: bold;
}

.ant-collapse-content, .ant-collapse-content.ant-collapse-content-active.ant-motion-collapse-enter.ant-motion-collapse-enter-active.ant-motion-collapse {
  background-color: #d9e7eb !important;
  /* overscroll-behavior: contain; */
  height: 15vh !important;
  overflow-y: auto !important;
}
