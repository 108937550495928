.layout {
    height: 100vh;
}

.ant-layout-header {
    --tw-bg-opacity: 1;
    padding-inline: 0px !important;
    color: white;
}

.footer {
    padding-inline: 0px !important;
}

.Content--Layout.internal {
    background-color: white;
    padding: 30px;
    border-radius: 0.5rem; /* 8px */
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 65vh;
}

button[disabled] {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none;
}

.ant-layout-sider-children {
    overflow: auto;
    scrollbar-width: none; 
}

.ant-layout-sider-children::-webkit-scrollbar {
    display: none;
}

.ant-menu-item-selected{
    background-color: #b7dce6 !important;
}