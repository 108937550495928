.equipe-switch{
    height: 40px !important;
    align-items: center;
    border-radius: 0.5rem !important; /* 8px */
}

.ant-switch .ant-switch-inner {
    margin-top: 7px !important;
}
.ant-switch .ant-switch-handle {
    margin-top: 7px !important;
    width: 20px;
    height: 20px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 30px);
}


.ant-switch .ant-switch-inner .ant-switch-inner-checked, .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    font-weight: 700 !important;
    font-size: 1.25rem !important; /* 20px */
}

.panel-header {
    font-weight: 700 !important;
    font-size: 1.25rem !important; /* 20px */
  }

.ant-collapse {
    border: 0px solid !important;
}

.ant-collapse-header-text {
    color: rgb(0, 90, 118);
}

.list-operator-name {
    line-height: 1.75rem;
    font-weight: 700;
    color: #005a76 !important;
    font-size: 1.125rem;
    place-items: center;
}