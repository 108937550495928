.overlay{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%; 
    width: 100%;
    background-color: rgba(163, 163, 163, 0.4); 
    z-index: 40; 
}

.boxContainer{
    margin-top: 17%;
    margin-left: 47%;
    background-color: white;
    border-radius: 2rem;
    width: 8rem;
    height: 8rem;
    justify-content: center;
}