body {
    background-color: #000;
}

.stopwatch-time {
    text-align: center;
    color: #000;
    font-size: 80px;
}

.stopwatch-buttons {
    display: flex;
    justify-content: center;
}

.stopwatch-buttons button {
    margin: 20px;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    color: #fff;
}

.stopwatch-buttons button:first-child {
    background-color: green;
}

.stopwatch-buttons button:last-child {
    background-color: red;
}


.ant-typography {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    margin-right: 10px;
}