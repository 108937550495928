.ant-steps.ant-steps-navigation {
	margin-bottom: 20px !important;
}

.ant-steps-item {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	border-radius: 0.5rem;
	margin-right: 40px;
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
	background-color: transparent;
}

.ant-steps.ant-steps-navigation .ant-steps-item::after {
	margin-left: 15px !important;
	margin-top: 5px;
    color: black;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container {
	padding-bottom: 7px;
	padding-top: 7px;
}

.ant-steps-item-title{
	overflow: visible !important;
}

.ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-title{
	margin-left: 14px !important; 
}	

.ant-steps .ant-steps-item-content {
	display: grid;
}

.ant-steps.ant-steps-small .ant-steps-item-icon {
	display: none;
}

.ant-steps-item.ant-steps-item-wait, .ant-steps-item.ant-steps-item-finish {
	background-color: #D9E7EB;
}

.ant-steps-item.ant-steps-item-wait .ant-steps-item-title, 
.ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
    line-height: 1.75rem;
    font-weight: 700;
	align-items: center;
	color: #005a76 !important;
	font-size: 1.125rem;
	justify-self: auto;
	place-items: center;
}

.ant-steps-item.ant-steps-item-process.ant-steps-item-active {
	background-color: #005a76;
}

.ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-title {
	/* 18px */
    line-height: 1.75rem;
	/* 28px */
    font-weight: 700;
	align-items: center;
	color: white !important;
	font-size: 1.125rem;
	justify-self: auto;
	place-items: center;
}

.ant-form-item .ant-form-item-label {
    text-align: start;
}

.form-container {
    border-bottom: 3px solid  #D9E7EB;
}

.form-container .login-form .ant-col.ant-form-item-label label {
    line-height: 1.75rem;
    font-weight: 700;
	color: #005a76 !important;
	font-size: 1.125rem;
	place-items: center;
}

.form-container .login-form .form-container-item {
    border-top:    3px solid  #D9E7EB;
    padding-top: 15px;
    margin-bottom: 10px;
}

.form-container .login-form .form-container-radio .ant-radio-button-wrapper {
    border-radius: 9999px;
    margin-right: 10px;
	background-color: #D9E7EB;
    line-height: 1.75rem;
    font-weight: 700;
	color: #005a76;
	font-size: 1.125rem;
	place-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
}

.ant-radio-button-wrapper {
    border-inline-start: 1px solid #d9d9d9;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

label.ant-radio-button-wrapper {
    border-color: transparent !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-color: transparent !important;
    background-color: #005a76 !important;
    color: white !important;
}

.ant-steps.ant-steps-navigation .ant-steps-item::after{
	transform: translateY(-50%) translateX(-30%);
	height: 30px;
	width: 30px;
	border: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='30' width='30' viewBox='20 20 448 512'><path d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'/></svg>");
}

.ant-steps.ant-steps-navigation .ant-steps-item .ant-steps-item-container{
	margin-inline-start: -12px;
    width: 100%;
    display: flex;
    justify-content: center;
}


