@media screen and (max-width: 1575px) {

  h1,
  h2 {
    color: white;
  }
}

.surgery-row {
	color: #005a76;
  font-weight: 600;
}

.ant-table-cell:has(.custom-truncate) {
  /* truncate */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}