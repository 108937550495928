.form-container {
    border-bottom: none;
}

/* same as .form-container .login-form .ant-col.ant-form-item-label label */

.ant-modal-title {
    text-align: center;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 700;
	color: #005a76 !important;
	place-items: center;
}

.ant-table-cell {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    font-weight: 600;
    color: #005a76 !important;
    width: auto;
    width: 100px;
    min-width: 100px !important;
}

.ant-table-cell.ant-table-cell-fix-left {
    color: #ff7222 !important;
}

