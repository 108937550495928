li.ant-picker-now a.ant-picker-now-btn {
    color: #fff;
    background-color: #029B9A;
    padding: 7px;
    font-weight: 800;
    border-radius: 6px;
}

li.ant-picker-ok button.ant-btn {
    color: #fff;
    background-color: #1677ff;
    font-weight: 800;
}