.ant-picker-calendar-header {
    padding-right: 20px !important;
}
.ant-picker-calendar-header .ant-radio-group {
    display: none;
}

.day-calendar-event {
	font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
}