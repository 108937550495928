.infusion-row {
    color: #005a76;
    font-weight: 600;
}

.form-container .login-form .form-container-item {
    border-top:    0px solid  #D9E7EB;
    padding-top: 15px;
    margin-bottom: 10px;
}

.form-container .login-form .form-container-radio .ant-radio-button-wrapper {
    border-radius: 9999px;
    margin-right: 10px;
	background-color: #D9E7EB;
    line-height: 1.75rem;
    font-weight: 700;
	color: #005a76;
	font-size: 1.125rem;
	place-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
}

.ant-radio-button-wrapper {
    border-inline-start: 1px solid #d9d9d9;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

label.ant-radio-button-wrapper {
    border-color: transparent !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-color: transparent !important;
    background-color: #005a76 !important;
    color: white !important;
}