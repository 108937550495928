/* TAILWINDCSS INIT */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #app, #app>div {
  height: 100%
}

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customButton {
  font-weight: 700 !important;
  font-size: 1.25rem !important; /* 20px */
  line-height: 1.75rem !important; /* 28px */
  color: white !important;
  padding-bottom: 35px !important;
  border-radius: 0.5rem !important; /* 8px */
  float: right !important;
}

.customButtonNoFloat {
  font-weight: 700 !important;
  font-size: 1.25rem !important; /* 20px */
  line-height: 1.75rem !important; /* 28px */
  color: white !important;
  padding-bottom: 35px !important;
  border-radius: 0.5rem !important; /* 8px */
}

.pullRight{
  display: flex !important;
  justify-content: right !important;
}

.css-dev-only-do-not-override-1vtf12y .ant-steps.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-inline-start: -12px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ant-btn-icon .align-center-icon{
  display:grid !important;
  transform: translate(-8px, 2px) !important;
  padding-right: 100px !important;
}

.ant-picker-ok {
  display: none !important;
}

.ant-layout-sider-trigger{
  height: 5.55rem !important;
  padding-top: 20px !important;
}

.ant-layout-sider-trigger .anticon{
  justify-content: center;
  transform: translate(0px, -10px) !important;
  width: 100% !important;
  height: 100% !important;
}

.ant-select.ant-select-in-form-item {
  margin-top: 0px;
}