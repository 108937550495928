.errorPageLeft{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 99%;
    background-color: #233141;
    display: flex;
    justify-content: center;
    z-index: 10;
    clip-path: polygon(0 0, 100% 0, 83% 100%, 0% 100%);
}

.mainBox{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 55%;
    filter: drop-shadow(6px 3px 20px rgba(0, 0, 0, 0.83));
}

.borderRight{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 83% 100%, 0% 100%);
    z-index: 0;
}

.errorPageRight{
    display: flex;
    justify-content: center;
    margin-left: 35%
}

@media only screen and (min-width: 1331px) {
    .imageLogo{
        margin-top: 36%;
        margin-right: 10%;
        height: 18%;
        width: 80%;
        color: white;
    }

    .writtenBig{
        margin-top: 28%;
        margin-left: 6%;
        width: 38%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 95px;
        color: #3B3B3B;
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 75%);
        margin-left: 16%;
        font-family: Georgia, serif;
        font-size: 45px;
        color: #3B3B3B;
    }
}

@media only screen and (max-width: 1330px) {
    .imageLogo{
        margin-top: 55%;
        margin-right: 10%;
        height: 12%;
        width: 70%;
    }

    .writtenBig{
        margin-top: 43%;
        margin-left: 6%;
        width: 40%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 60px;
        color: #3B3B3B;
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 63%);
        margin-left: 14%;
        font-family: Georgia, serif;
        font-size: 40px;
        color: #3B3B3B;
    }
}

@media only screen and (max-width: 950px) {
    .imageLogo{
        margin-top: 65%;
        margin-right: 10%;
        height: 12%;
        width: 70%;
    }

    .writtenBig{
        margin-top: 53%;
        margin-left: 6%;
        width: 38%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 45px;
        color: #3B3B3B; 
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 58%);
        margin-left: 15%;
        font-family: Georgia, serif;
        font-size: 35px;   
        color: #3B3B3B;
    }
}

@media only screen and (max-width: 750px) {
    .imageLogo{
        margin-top: 92%;
        margin-right: 8%;
        height: 8%;
        width: 70%;
    }

    .writtenBig{
        margin-top: 70%;
        margin-left: 6%;
        width: 38%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 40px;
        color: #3B3B3B;
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 45%);
        margin-left: 16%;
        font-family: Georgia, serif;
        font-size: 25px;
        color: #3B3B3B;
    }
}

@media only screen and (max-width: 500px) {
    .imageLogo{
        margin-top: 112%;
        margin-right: 10%;
        height: 7%;
        width: 80%;
    }

    .writtenBig{
        margin-top: 90%;
        margin-left: 6%;
        width: 38%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 30px;
        color: #3B3B3B;
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 32%);
        margin-left: 16%;
        font-family: Georgia, serif;
        font-size: 20px;
        color: #3B3B3B;
    }
}

@media only screen and (max-width: 500px) {
    .imageLogo{
        margin-top: 122%;
        margin-right: 10%;
        height: 7%;
        width: 80%;
    }

    .writtenBig{
        margin-top: 100%;
        margin-left: 6%;
        width: 38%;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 20px;
        color: #3B3B3B;
        white-space: nowrap;
    }

    .writtenSmall{
        position: absolute;
        width: 36%;
        margin-top: calc(100% - 22%);
        margin-left: 16%;
        font-family: Georgia, serif;
        font-size: 20px;
        color: #3B3B3B;
    }
}