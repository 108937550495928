body {
    background-color: white;
}

.backGround{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 60%;
    z-index: 0;
    background: linear-gradient(70deg, blue, pink);
}

.fade{
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 60%;
    z-index: 0;
    background: linear-gradient(90deg, white, rgba(254, 254, 255, 0.1));
}

.title{
    position: absolute;
    left: 4rem;
    top: 2rem;
    width: 80%;
    z-index: 2;
}

.formCell{
    position: absolute;
    left: 0px;
    top: calc(8% - 8rem); 
    height: 90%;
    width: 30%;
    padding-top: 15%; 
    z-index: 10; 
    padding-right: 20px;
}